@import "~hamburgers/dist/hamburgers.min.css";
@import "~animate.css";
@import "~slick-carousel/slick/slick.css";

//@import '~quill/dist/quill.core.css';
//@import '~quill/dist/quill.snow.css';

button:focus {
    outline: none !important;
}
