
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Roboto:ital,wght@0,300;0,400;1,900&display=swap');

/*@import "~slick-carousel/slick/slick.css";*/
/*@import "~animate.css";*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    font-family: 'Noto Sans TC', 'Roboto', sans-serif;
}

.slick-prev,
.slick-next {
    font-size: 0;
    position: absolute;
    bottom: 20px;
    color: white;
    border: 0;
    background: none;
    z-index: 1;
}

.slick-prev {
    left: 20px;
    top: 20px;
}

.slick-prev:after {
    /*content: "\f104";*/
    /*font: 40px/1 'FontAwesome';*/
}

.slick-next {
    right: 20px;
    top: 20px;
    text-align: right;
}

.slick-next:after {
    /*content: "\f105";*/
    /*font: 40px/1 'FontAwesome';*/
}

.slick-prev:hover:after,
.slick-next:hover:after {
    color: #7e7e7e;
}
